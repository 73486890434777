@font-face {
  font-family: 'gt_alpinalight';
  src: url('./GT-Alpina/GT-Alpina-Standard-Light.eot'); /* IE9 Compat Modes */
  src: url('./GT-Alpina/GT-Alpina-Standard-Light.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./GT-Alpina/GT-Alpina-Standard-Light.woff') format('woff'),
    /* Pretty Modern Browsers */ url('./GT-Alpina/GT-Alpina-Standard-Light.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'gt_alpinalight';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: relative;
  background-color: #80d993;
  overflow: hidden;
}

html,
body,
.app,
.root {
  touch-action: none;
}

h1 {
  padding-top: 75px;
  margin-top: 0%;
  margin-block-start: 0em;
  font-size: 40px;
  font-weight: normal;
  margin-bottom: 1.2em;
  position: relative;
}
h1:after {
  content: '';
  position: absolute;
  left: 0;
  height: 0;
  bottom: -13px;
  border-top: 1px solid transparent;
  border-left: 390px solid black;
  border-bottom: 2px solid transparent;
}
@media (max-width: 700px) {
  h1 {
    font-size: 30px;
    padding-top: 60px;
  }
  h1:after {
    bottom: -10px;
    border-top: 1px solid transparent;
    border-left: 292px solid black;
    border-bottom: 2px solid transparent;
  }
}

p {
  margin-top: 0em;
  margin-bottom: 24px;
}
@media (max-width: 700px) {
  p {
    margin-bottom: 19px;
  }
}

.colofon-text {
  position: absolute;
  display: block;
  z-index: 2;
  background-color: #80d993ec;
  font-size: 26px;
  letter-spacing: 0.02em;
  line-height: 1.2em;
  text-shadow: 0 0 black;
  right: 0px;
  top: 0px;
  width: 525px;
  height: 100%;
  padding-right: 25px;
  padding-left: 25px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-padding-bottom: 0px;
  -ms-overflow-style: none; /* IE hide scrollbar */
  scrollbar-width: none; /* Firefox hide scrollbar */
  user-select: none; /* Standard */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
}

.colofon-text > .video {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.colofon-text > .video > iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

@media (max-width: 700px) {
  .colofon-text {
    font-size: 21px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

::-webkit-scrollbar {
  width: 0px;
}

button {
  position: absolute;
  display: block;
  z-index: 99;
  background-color: #00000000;
  font-family: 'gt_alpinalight';
  color: black;
  border: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

button:focus {
  outline: none !important;
}

.questionmarkButton {
  width: 60px;
  height: 60px;
  top: 5px;
  right: 5px;
  font-size: 48px;
}
@media (max-width: 700px) {
  .questionmarkButton {
    top: 0;
    right: 0 !important;
  }
}

.closeButton {
  background: url(/close_button.svg) no-repeat;
  width: 20px;
  height: 20px;
  top: 25px;
  right: 25px;
}
@media (max-width: 700px) {
  .closeButton {
    top: 15px;
    right: 15px;
  }
}

.copyLinkButton {
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(calc(-50% - 45px), 0);
  transform: translate(calc(-50% - 45px), 0);
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: inherit;
  letter-spacing: 0.05em;
  text-align: center;
  text-shadow: 0 0 black;
  border: 1px solid;
  border-radius: 50px;
  box-shadow: -1px 1px black;
  width: 266px;
}
.copyLinkButton:active {
  box-shadow: 0 0 black;
  -ms-transform: translate(calc(-50% - 45px - 1px), 1px);
  transform: translate(calc(-50% - 45px - 1px), 1px);
}
@media (max-width: 700px) {
  .copyLinkButton {
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    width: 222px;
  }
  .copyLinkButton:active {
    -ms-transform: translate(calc(-50% - 1px), 1px);
    transform: translate(calc(-50% - 1px), 1px);
  }
}

.linkButtonContainer {
  padding-bottom: 36px;
}
@media (max-width: 700px) {
  .linkButtonContainer {
    padding-bottom: 32px;
  }
}

a {
  text-decoration: none;
  color: black;
  border-bottom: 1px solid currentColor;
}
a:hover {
  /* color: orangered; */
  /* color: #746d75ff; */
  /* color: #61b4d8ff;  */
  color: rgb(46, 171, 224);
  /* color: rgb(49, 149, 193); */
}

.logo-image {
  position: absolute;
  padding-top: 5px;
  padding-bottom: 25px;
  width: 300px;
  right: 25px;
}
@media (max-width: 700px) {
  .logo-image {
    padding-bottom: 15px;
    width: 250px;
    right: 15px;
  }
}
